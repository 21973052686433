import * as React from "react";
import Header from "../../components/common/header/Header";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Link,
  Breadcrumbs,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClientSideMenu from "./ClientSideMenu";
import Pagination from "@mui/material/Pagination";
import { ReadOrder } from "../../interfaces/core/ReadOrder";
import { getOrders } from "../../services/core-api";
import theme from "../../theme";
import Footer from "../../components/common/Footer/Footer";

function Row(props: { row: ReadOrder }) {
  const baseURL = process.env.REACT_APP_API_URL;
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [currency, setCurrency] = React.useState('');

  React.useEffect(() => {
    const authUser = localStorage.getItem("user"); 
  
    if (authUser) {
        const user = JSON.parse(authUser);
        if (user.userType === 'servis'){
          setCurrency('LEK');
        } else {
          setCurrency('EUR');
        }
    }
}, []);

  const handleExportPdf = (id: number) => {
    const url = `${baseURL}/order/${id}/export`;
    window.open(url);
  };

  const date = new Date(row.created_at);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ padding: "5px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: "#F37C2E" }} />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left" sx={{ padding: "5px" }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "13px", fontFamily: "Inter" }}
          >
            {row.id}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ padding: "5px" }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "13px", fontFamily: "Inter" }}
          >
            {`${formattedDate} ${formattedTime}`}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ padding: "5px" }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "13px", fontFamily: "Inter" }}
          >
            {row.total} {currency}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ padding: "5px" }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "13px", fontFamily: "Inter" }}
          >
            {row.payment}
          </Typography>
        </TableCell>
        {row.status === "Delivered" ? (
          <TableCell align="right" sx={{ padding: "5px" }}>
            <Typography
              variant="body1"
              sx={{ fontSize: "13px", color: "#2ccb32", fontFamily: "Inter" }}
            >
              {row.status}
            </Typography>
          </TableCell>
        ) : row.status === "Processing" ? (
          <TableCell align="right" sx={{ padding: "5px" }}>
            <Typography
              variant="body1"
              sx={{ fontSize: "13px", color: "#F22", fontFamily: "Inter" }}
            >
              {row.status}
            </Typography>
          </TableCell>
        ) : row.status === "Packed" ? (
          <TableCell align="right" sx={{ padding: "5px" }}>
            <Typography
              variant="body1"
              sx={{ fontSize: "13px", color: "#00F", fontFamily: "Inter" }}
            >
              {row.status}
            </Typography>
          </TableCell>
        ) : (
          <TableCell align="right" sx={{ padding: "5px" }}>
            <Typography
              variant="body1"
              sx={{ fontSize: "13px", color: "#FFB122", fontFamily: "Inter" }}
            >
              {row.status}
            </Typography>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1, border: "2px solid #f1f1f1", p: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead sx={{ bgcolor: "#f1f1f1", p: 1 }}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: 600,
                        }}
                      >
                        Article number
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: 600,
                        }}
                      >
                        Manufacturer
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: 600,
                        }}
                      >
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: 600,
                        }}
                      >
                        Quantity
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: 600,
                        }}
                      >
                        Price (&#8364;)
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: 600,
                        }}
                      >
                        Total price (&#8364;)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "13px", fontFamily: "Inter" }}
                        >
                          {item.articleNumber}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "13px", fontFamily: "Inter" }}
                        >
                          {item.mfrName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <span
                          style={{ fontSize: "13px", fontFamily: "Inter" }}
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "13px", fontFamily: "Inter" }}
                        >
                          {item.quantity}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "13px", fontFamily: "Inter" }}
                        >
                          {item.price} {currency}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "13px", fontFamily: "Inter" }}
                        >
                          {item.totalPrice} {currency}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#f1f1f1",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    borderRadius: 0,
                  }}
                  onClick={() => handleExportPdf(row.id)}
                >
                  Export PDF
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const OrdersPage = () => {
  const [orders, setOrders] = React.useState<ReadOrder[] | []>([]);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Dayjs | null>(
    null
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Dayjs | null>(
    null
  );
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 20;
  const [count, setCount] = React.useState(0);
  const [currency, setCurrency] = React.useState('');

  React.useEffect(() => {
    const authUser = localStorage.getItem("user"); 
  
    if (authUser) {
        const user = JSON.parse(authUser);
        if (user.userType === 'servis'){
          setCurrency('LEK');
        } else {
          setCurrency('EUR');
        }
    }
}, []);

  const fetchOrders = React.useCallback(async () => {
    try {
      const options = {
        from_date: selectedFromDate
          ? selectedFromDate.format("YYYY-MM-DD")
          : "",
        to_date: selectedToDate ? selectedToDate.format("YYYY-MM-DD") : "",
        page_number: page,
      };
      const response = await getOrders(options);
      if (response) {
        setOrders(response.orders);
        setPage(response.page_number);
        setCount(Math.ceil(response.total / rowsPerPage));
      }
    } catch (error) {
      setOrders([]);
      setPage(1);
      setCount(0);
    }
  }, [selectedFromDate, selectedToDate, page, rowsPerPage]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchOrders();
    }
    return () => {
      isMounted = false;
    };
  }, [fetchOrders]);

  const handleFromDateChange = (date: Dayjs | null) => {
    setSelectedFromDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
    setPage(1); // Reset to first page on date change
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        <Header />
        <Box
          sx={{
            flexGrow: 1,
            m: 2,
            minHeight: '71vh'
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ClientSideMenu />
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
              <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    sx={{ paddingLeft: "0px !important" }}
                  >
                    <Stack
                      spacing={2}
                      sx={{ paddingTop: "3px", paddingLeft: "15px" }}
                    >
                      <Breadcrumbs 
                        separator={
                          <NavigateNextIcon
                            sx={{ color: "black" }}
                            fontSize="small"
                          />
                        }
                        aria-label="breadcrumb">
                      <Link
                        underline="hover"
                        key="2"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 400,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                        href="/"
                      >
                        Home
                      </Link>
                      ,
                        <Typography
                          key="3"
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: 600,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                        >
                          Orders
                        </Typography>
                      </Breadcrumbs>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        pr: 1,
                      }}
                    >
                      <Box>
                        <DatePicker
                          label="From Date"
                          value={selectedFromDate}
                          onChange={handleFromDateChange}
                          format="MM/DD/YYYY"
                          sx={{
                            bgcolor: "#fff",
                            mr: 1,
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#5d5d5d",
                              top: "-10px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                        />
                        <DatePicker
                          label="To Date"
                          value={selectedToDate}
                          onChange={handleToDateChange}
                          format="MM/DD/YYYY"
                          sx={{
                            bgcolor: "#fff",
                            mr: 1,
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#5d5d5d",
                              top: "-10px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ overflowX: "auto" }}>
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", minWidth: "800px" }}
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                        >
                          Order No
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pb: 0,
                          }}
                          align="center"
                        >
                          Date & Time
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                          align="right"
                        >
                          Total value
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                          align="right"
                        >
                          Payment method
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            pr: 0,
                            pb: 0,
                          }}
                          align="right"
                        >
                          Status Order
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map((row) => (
                        <Row key={row.id} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2}>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", pt: 1 }}
                  >
                    <Pagination
                      count={count}
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </>
    </LocalizationProvider>
  );
};

export default OrdersPage;
