import { Grid, Typography, Box, styled, Paper } from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../../../theme";
import { ArticleCriteria } from "../../../interfaces/ArticleCriteria";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  boxShadow: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Inter",
    fontWeight: 500,
    padding: 5,
    fontSize: 12,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 5,
    fontFamily: "Inter",
    fontWeight: 400,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProductDetails = (props: any) => {
  const { article } = props;
  const defaultImage = "./no-image.png";

  return (
    <>
      {article ? (
        <>
          <Grid item xs={12} sm={12} md={4}>
            <Item>
              <img
                style={{ maxWidth: "100%" }}
                src={article.images[0]?.imageURL3200 || defaultImage}
                alt="Product photos"
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            {article && (
              <>
                <Item
                  sx={{ bgcolor: "#f1f1f1", borderRadius: 0, marginTop: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: "left",
                      color: theme.palette.secondary.dark,
                      fontSize: "13px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                    }}
                  >
                    Characteristics
                  </Typography>
                </Item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {article.articleCriteria.map(
                            (criteria: ArticleCriteria, index: number) => (
                              <StyledTableCell key={index}>
                                {criteria.criteriaDescription}
                              </StyledTableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          {article.articleCriteria.map(
                            (criteria: ArticleCriteria, index: number) => (
                              <StyledTableCell
                                key={index}
                                component="th"
                                scope="row"
                              >
                                {criteria.formattedValue}
                              </StyledTableCell>
                            )
                          )}
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Item
                  sx={{ bgcolor: "#f1f1f1", borderRadius: 0, marginTop: 4 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: "left",
                      color: theme.palette.secondary.dark,
                      fontSize: "13px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                    }}
                  >
                    Part Information
                  </Typography>
                </Item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Parts State</StyledTableCell>
                          <StyledTableCell>Packing Unit</StyledTableCell>
                          <StyledTableCell>Batch Size</StyledTableCell>
                          <StyledTableCell>EAN</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {article.misc.articleStatusDescription}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {article.misc.quantityPerPackage}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <span>{article.misc.batchSize1}</span>
                            <br />
                            {article.misc.batchSize2}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {article.gtins}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={1}>
                    {article?.links?.map((link: any, index: any) => (
                      <Grid key={index} item xs={6} sm={6} md={2}>
                        <OpenInNewIcon
                          sx={{
                            mr: 0.5, // Add some right margin to space the icon and text
                            fontSize: "11px",
                          }}
                        />
                        <Link
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            textDecoration: "none", // Optional: remove underline from the link
                            color: "inherit", // Optional: inherit color from parent or use a specific color
                          }}
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.headerDescription}
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={1}>
                    {article?.pdfs?.map((pdf: any, index: any) => (
                      <Grid key={index} item xs={6} sm={6} md={2}>
                        <PictureAsPdfIcon
                          sx={{
                            mr: 0.5, // Add some right margin to space the icon and text
                            fontSize: "12px",
                          }}
                        />
                        <Link
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            textDecoration: "none", // Optional: remove underline from the link
                            color: "inherit", // Optional: inherit color from parent or use a specific color
                          }}
                          href={pdf.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {pdf.headerDescription}
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
            {!article && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div>
                  <img
                    style={{ width: "100px", height: "auto" }}
                    src="./no_data.png"
                    alt="No data"
                  />
                </div>
                <div>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.secondary.dark,
                      fontFamily: "Inter",
                      fontWeight: "normal",
                    }}
                  >
                    No Data!
                  </Typography>
                </div>
              </Box>
            )}
          </Grid>
        </>
      ) : (
        <Box></Box>
      )}
    </>
  );
};

export default ProductDetails;
