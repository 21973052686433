import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../components/common/header/Header";
import { Article } from "../interfaces/Article";
import Stack from "@mui/material/Stack";
import Footer from "../components/common/Footer/Footer";
import theme from "../theme";
import { Typography, Breadcrumbs, Container } from "@mui/material";
import { getLocalProductByInternalNumber } from "../services/core-api";
import LocalProductItem from "../components/products/LocalProductItem";
import ProductItem from "../components/products/ProductItem";

const LocalProductSearchPage = () => {
   const [article, setArticle] = React.useState<Article | null>(null);
   const [internalNumber, setInternalNumber] = React.useState('');
   const [brandId, setBrandId] = React.useState(0);
   const [page, setPage] = React.useState(1);
  const [isWrongPath, setIsWrongPath] = React.useState(false);

 const isFirstRun = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const urlParams = new URLSearchParams(window.location.search);
      const internalNumberParam = urlParams.get("keyword");
      const brandIdParam = urlParams.get("brand");

      if (internalNumberParam == null) {
         setTimeout(() => {
            setIsWrongPath(true)
         }, 1000);
      }

      if (internalNumberParam){
         setInternalNumber(internalNumberParam);
      }

      if (brandIdParam) {
         const brandIdUrlParam = parseInt(brandIdParam, 10);
         setBrandId(brandIdUrlParam);
      }
    }
    }, []);

    React.useEffect(() => {
      if (internalNumber) {
         (async () => {
            try {
               const options: {
                  internalNumber: string;
                  page: number;
                  brand?: number; // Optional brand property
                } = {
                  internalNumber: internalNumber,
                  page: page,
                };
        
                // Conditionally add the brand only if brandId is different from 0
                if (brandId !== 0) {
                  options.brand = brandId;
                }
               const response = await getLocalProductByInternalNumber(options);
               if (response) {
                  setArticle(response);
               }
            } catch (error) {
            }
         })()
      }
    },[brandId, internalNumber, page])

return (
   <>
      <Header/>
         <Box
          sx={{
            flexGrow: 1,
            m: 2,
            minHeight: '71vh',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: '100vh'
          }}
        >
         <Container maxWidth="lg">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
               <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
               >
                  <Grid container spacing={1}>
                     <Grid item xs={12} sm={12} md={5} sx={{paddingLeft: '0px !important', }}>
                        <Stack spacing={2} sx={{paddingTop: '3px', paddingLeft: '5px'}}>
                           <Breadcrumbs aria-label="breadcrumb">
                              <Typography key="3"
                                 sx={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    fontFamily: "Inter",
                                 }}
                              >
                                 Products
                              </Typography>
                           </Breadcrumbs>
                        </Stack>
                     </Grid>
                  </Grid>
               </Box>
               <Box sx={{ mt: 4 }}>

                {article && article.tec_doc_article ? (
                  <ProductItem article={article} />
                ):(
                  <LocalProductItem article={article} />
                )}
               </Box>
            </Grid>
            </Grid>
         </Container>
        </Box>
      <Footer/>
   </>
)
}
export default LocalProductSearchPage;
