import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../components/common/header/Header";
import ProductCategories from "../components/products/ProductCategories";
import { Article } from "../interfaces/Article";
import {
  fetchArticles,
  fetchGenericArticleFacets,
} from "../services/tecdocService";
import { DataSupplierFacet } from "../interfaces/DataSupplierFacet";
import ProductBrands from "../components/products/ProductBrands";
import ProductItem from "../components/products/ProductItem";
import ProductCriteria from "../components/products/ProductCriteria";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { GroupedCriteria } from "../interfaces/GroupedCriteria";
import { CriteriaFilters } from "../interfaces/CriteriaFilters";
import CustomBreadcrumbs from "../components/common/Breadcrumbs/Breadcrumbs";
import theme from "../theme";
import CompareProducts from "../components/products/CompareProducts";
import { VehicleProductGroup } from "../interfaces/VehicleProductGroup";
import LocalProductItem from "../components/products/LocalProductItem";
import { GenericArticleFacetCounts } from "../interfaces/GenericArticleFacetCounts";
import { Virtuoso } from "react-virtuoso";

interface Film {
  title: string;
  year: number;
}

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const ProductSearchPage = () => {
  const controllerRef = React.useRef<AbortController | null>(null);
  const [genericArticles, setGenericArticles] = React.useState<
    GenericArticleFacetCounts[]
  >([]);
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(
    null
  );
  const [linkageTargetType, setLinkageTargetType] = React.useState<
    string | null
  >(null);
  const [dataSupplierFacets, setDataSupplierFacets] =
    React.useState<DataSupplierFacet | null>(null);
  const [assemblyGroupFacets, setAssemblyGroupFacets] =
    React.useState<VehicleProductGroup | null>(null);
  const [articles, setArticles] = React.useState<Article[] | null>(null);
  const [articleNumber, setArticleNumber] = React.useState("");
  const [categoryId, setCategoryId] = React.useState<number | null>(null);
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [assemblyGroupNodeIds, setAssemblyGroupNodeIds] = React.useState<
    number | null
  >(null);
  const [groupedCritera, setGroupedCriteria] =
    React.useState<GroupedCriteria | null>(null);
  const [criteriaFilters, setCriteriaFilters] = React.useState<
    CriteriaFilters[] | null
  >(null);
  const [isWrongPath, setIsWrongPath] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  // Pagination
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const rowsPerPage = 1000;

  const open = false;
  const [options, setOptions] = React.useState<readonly Film[]>([]);
  const loading = open && options.length === 0;

  const [comparisonArticles, setComparisonArticles] = React.useState<Article[]>(
    []
  );

  const updateComparisonArticles = (article: Article, isChecked: boolean) => {
    if (isChecked) {
      // Add article to comparison array
      setComparisonArticles((prevArticles) => [...prevArticles, article]);
    } else {
      // Remove article from comparison array
      setComparisonArticles((prevArticles) =>
        prevArticles.filter((a) => a !== article)
      );
    }
  };

  const updateCategories = (newCategoryId: number) => {
    setIsLoading(true);
    setArticles([]);
    setCategoryId(newCategoryId);
  };

  const updateCriteriaFilters = (
    criteriaId: number,
    rawValue: string,
    isChecked: boolean
  ) => {
    setIsLoading(true);
    setArticles([]);
    setCriteriaFilters((prevFilters) => {
      const updatedFilters = prevFilters ? [...prevFilters] : [];

      if (isChecked) {
        // Add the criteria to the filters if it is checked
        updatedFilters.push({ criteriaId, rawValue });
      } else {
        // Remove the criteria if it is unchecked
        return updatedFilters.filter(
          (filter) =>
            filter.criteriaId !== criteriaId || filter.rawValue !== rawValue
        );
      }

      return updatedFilters;
    });
  };

  const isFirstRun = React.useRef(true);

  React.useEffect(() => {
    controllerRef.current = new AbortController();
    if (isFirstRun.current) {
      isFirstRun.current = false;

      const urlParams = new URLSearchParams(window.location.search);
      const linkageTargetType = urlParams.get("linkageTargetType");
      const linkageTargetId = urlParams.get("linkageTargetId");
      const categoryIdParam = urlParams.get("category");
      const articleNumber = urlParams.get("keyword");
      const brandIdParam = urlParams.get("brand");
      const assemblyGroupNodeIdsParam = urlParams.get("assemblyGroupNodeIds");

      if (
        linkageTargetType == null ||
        linkageTargetId == null ||
        brandIdParam == null ||
        assemblyGroupNodeIdsParam == null
      ) {
      }

      if (linkageTargetId) {
        const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
        setLinkageTargetId(linkageTargetIdUrlParam);
      }

      if (linkageTargetType) {
        setLinkageTargetType(linkageTargetType);
      }

      if (categoryIdParam) {
        const categoryIdUrlParam = parseInt(categoryIdParam, 10);
        setCategoryId(categoryIdUrlParam);
      }

      if (articleNumber) {
        setArticleNumber(articleNumber);
      }

      if (brandIdParam) {
        const brandIdUrlParam = parseInt(brandIdParam, 10);
        setBrandId(brandIdUrlParam);
      }

      if (assemblyGroupNodeIdsParam) {
        const assemblyGroupNodeIdsUrlParam = parseInt(
          assemblyGroupNodeIdsParam,
          10
        );
        setAssemblyGroupNodeIds(assemblyGroupNodeIdsUrlParam);
      }

      // Extract all criteria parameters
      const criteriaParams: CriteriaFilters[] = [];
      urlParams.forEach((value, key) => {
        if (key === "criteria") {
          const [criteriaId, rawValue] = value.split(":");
          criteriaParams.push({
            criteriaId: parseInt(criteriaId, 10),
            rawValue,
          });
        }
      });

      setCriteriaFilters(criteriaParams);
    }
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    controllerRef.current = new AbortController();
    let isMounted = true;
    if (isMounted) {
      (async () => {
        try {
          const options = {
            category: categoryId,
            page: page,
            brand: brandId,
            linkageTargetType: linkageTargetType,
            linkageTargetId: linkageTargetId,
            assemblyGroupNodeIds: assemblyGroupNodeIds,
            criteria: criteriaFilters,
            // Add more optional parameters as needed
          };
          if (articleNumber || categoryId || brandId || assemblyGroupNodeIds) {
            const articlesResponse = await fetchArticles(
              articleNumber,
              options
            );
            if (!articlesResponse) return;
            const articles = articlesResponse.articles;
            setCount(
              Math.ceil(articlesResponse.totalMatchingArticles / rowsPerPage)
            );
            setArticles(articles);
            setIsLoading(false);

            const assemblyGroupFacets =
              articlesResponse.assemblyGroupFacets.counts;
            if (!assemblyGroupFacets) return;
            setAssemblyGroupFacets(assemblyGroupFacets);

            // Get dataSupplierFacets from response
            const dataSupplierFacets = articlesResponse.dataSupplierFacets;
            if (!dataSupplierFacets) return;
            setDataSupplierFacets(dataSupplierFacets);

            if (!categoryId) return;
            setGroupedCriteria(articlesResponse.groupedCriteria);
          }
        } catch (error) {
          setIsLoading(false);
        }
      })();
    }

    return () => {
      isMounted = false;
      controllerRef.current?.abort();
    };
  }, [
    brandId,
    categoryId,
    criteriaFilters,
    page,
    assemblyGroupNodeIds,
    linkageTargetType,
    linkageTargetId,
    articleNumber,
  ]);

  React.useEffect(() => {
    controllerRef.current = new AbortController();
    let isMounted = true;
    if (isMounted) {
      (async () => {
        try {
          const options = {
            page: page,
            brand: brandId,
            linkageTargetType: linkageTargetType,
            linkageTargetId: linkageTargetId,
            assemblyGroupNodeIds: assemblyGroupNodeIds,
          };
          if (articleNumber || brandId || assemblyGroupNodeIds) {
            const genericArticlesResponse = await fetchGenericArticleFacets(
              articleNumber,
              options
            );
            if (!genericArticlesResponse) return;
            setGenericArticles(genericArticlesResponse);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      })();
    }

    return () => {
      isMounted = false;
      controllerRef.current?.abort();
    };
  }, [
    brandId,
    page,
    assemblyGroupNodeIds,
    linkageTargetType,
    linkageTargetId,
    articleNumber,
  ]);

  // Use another useEffect to log the state whenever it changes
  React.useEffect(() => {}, [dataSupplierFacets]);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...topFilms]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const renderProductItem = (index: number) => {
    if (!articles || index >= articles.length) return null;

    const article = articles[index];
    return article.tec_doc_article ? (
      <ProductItem
        key={article.articleNumber}
        article={article}
        onToggle={updateComparisonArticles}
      />
    ) : (
      <LocalProductItem key={article.articleNumber} article={article} />
    );
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Box
            sx={{
              flexGrow: 1,
              m: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2.5}>
                <ProductCategories />
                <ProductCategories />
              </Grid>
              <Grid item xs={12} sm={12} md={9.5}>
                <Box
                  sx={{
                    bgcolor: "#f1f1f1",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: 1,
                    borderTop: `2px solid ${theme.palette.primary.dark}`,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      sx={{
                        paddingLeft: "0px !important",
                        paddingTop: "0px !important",
                      }}
                    >
                      <CustomBreadcrumbs />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", pr: 1 }}
                      >
                        <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                          <Skeleton
                            animation="wave"
                            sx={{ width: "200px", height: "30px" }}
                          />
                        </Box>
                        <Box sx={{ flexGrow: 3, marginRight: 2 }}>
                          <Skeleton
                            animation="wave"
                            sx={{ width: "200px", height: "30px" }}
                          />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <Skeleton
                            animation="wave"
                            sx={{ width: "200px", height: "30px" }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <ProductItem />
                </Box>
              </Grid>
            </Grid>
            <Stack spacing={2} sx={{ pt: 2 }}>
              <Skeleton animation="wave" sx={{ width: "100px" }} />
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            m: 2,
            minHeight: "71vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ProductCategories
                genericArticles={genericArticles}
                onCategoryChange={updateCategories}
              />
              {categoryId !== null && (
                <ProductCriteria
                  groupedCriteria={groupedCritera}
                  onCriteriaChange={updateCriteriaFilters}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
              <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    sx={{ paddingLeft: "0px !important" }}
                  >
                    <CustomBreadcrumbs
                      assemblyGroupFacets={assemblyGroupFacets}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "flex-start", sm: "flex-end" },
                        pr: 1,
                      }}
                    >
                      <Box
                        sx={{
                          mr: 1,
                          display: {
                            xs: "none",
                            sm: "block",
                            md: "block",
                            lg: "block",
                          },
                        }}
                      >
                        <CompareProducts compareArticles={comparisonArticles} />
                      </Box>
                      <Box sx={{ mr: 1 }}>
                        <ProductBrands
                          dataSupplierFacets={dataSupplierFacets}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Virtuoso
                  style={{ height: "calc(85vh - 150px)", width: "100%" }}
                  totalCount={articles?.length}
                  itemContent={renderProductItem}
                />
              </Box>
            </Grid>
          </Grid>
          {/* <Stack spacing={2} sx={{ pt: 2 }}>
            <Pagination
              count={count}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </Stack> */}
        </Box>
      )}
      {/* <Footer /> */}
    </>
  );
};

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topFilms = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
];

export default ProductSearchPage;
