import * as React from 'react';
import Header from '../components/common/header/Header';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import VehicleDetail from '../components/common/VehicleDetails/VehicleDetail';
import Footer from '../components/common/Footer/Footer';
import AssemblyTree from '../components/common/Assemblies/AssemblyTree';
import { VehicleProductGroup } from '../interfaces/VehicleProductGroup';
import { getAssemblyGroupFacets } from '../services/tecdocService';
import { DataSupplierFacet } from '../interfaces/DataSupplierFacet';
import SearchLiveAssemblyGroups from '../components/common/Assemblies/SearchLiveAssemblyGroups';
import ShortcutAssemblies from '../components/common/Assemblies/ShortcutAssemblies';
import ProductBrands from '../components/products/ProductBrands';
import theme from '../theme';

const AssemblyTreePage = () => {
   const [vehicleProductGroups, setVehicleProductGroups] = React.useState<VehicleProductGroup[] | null>(null);
   const [activeVehicleProductGroup, setActiveVehicleProductGroup] = React.useState<VehicleProductGroup | null>(null);
   const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(null);
   const [linkageTargetType, setLinkageTargetType] = React.useState<string | null>(null);
   const [brandId, setBrandId] = React.useState<number | null>(null);
   const [assemblyGroupNodeIds, setAssemblyGroupNodeIds] = React.useState<number | null>(null);
   const [dataSupplierFacets, setDataSupplierFacets] = React.useState<DataSupplierFacet | null>(null);


   React.useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const linkageTargetType = urlParams.get("linkageTargetType");
      const linkageTargetId = urlParams.get("linkageTargetId");
      const brandId = urlParams.get("brand");
      const assemblyGroupNodeIds = urlParams.get("assemblyGroupNodeIds");
  
      if (linkageTargetId) {
        const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
        setLinkageTargetId(linkageTargetIdUrlParam);
      }
  
      if (linkageTargetType) {
        setLinkageTargetType(linkageTargetType);
      }
  
      if (brandId) {
        const brandIdUrlParam = parseInt(brandId, 10);
        setBrandId(brandIdUrlParam);
      }

      if (assemblyGroupNodeIds) {
         const assemblyGroupNodeIdsUrlParam = parseInt(assemblyGroupNodeIds, 10);
         setAssemblyGroupNodeIds(assemblyGroupNodeIdsUrlParam);
       }
   }, []);

   React.useEffect(() => {
      if (linkageTargetId !== null && linkageTargetType !== null) {
         fetchAssemblyGroups(linkageTargetType, linkageTargetId);
      }
    }, [linkageTargetId, linkageTargetType]);

    const fetchAssemblyGroups = async (
      linkageTargetType: string,
      linkageTargetId: number
    ) => {
      try {
        const options = { 
          brand: brandId,
          assemblyGroupNodeIds: assemblyGroupNodeIds
        }
  
        const response = await getAssemblyGroupFacets(
          linkageTargetType,
          linkageTargetId,
          options
        );
        if (response) {
          const assemblyGroupFacets = response.assemblyGroupFacets.counts;
          const filteredAssemblyGroupFacets = assemblyGroupFacets.filter(
            (facet: { parentNodeId: number }) => facet.parentNodeId === assemblyGroupNodeIds
          );

          const activeParent = assemblyGroupFacets.filter(
            (facet: { assemblyGroupNodeId: number }) => facet.assemblyGroupNodeId === assemblyGroupNodeIds
          );
          
          setActiveVehicleProductGroup(activeParent[0]);
          setVehicleProductGroups(filteredAssemblyGroupFacets);
          const dataSupplierFacets = response.dataSupplierFacets;
          setDataSupplierFacets(dataSupplierFacets);
        }
      } catch (error) {
      }
    };


   return (
      <>
         <Header/>
         <Box sx={{m: 2}}>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12} md={2.5}>
                  <VehicleDetail/>
               </Grid>
               <Grid item xs={12} sm={12} md={9.5}>
               <Box
        sx={{
          bgcolor: "#f1f1f1",
          p: 1.1,
          borderTop: `2px solid ${theme.palette.primary.dark}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            pt: 1,
            pb: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "15px",
              textAlign: "left",
            }}
          >
            {activeVehicleProductGroup?.assemblyGroupName}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 1, mb: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShortcutAssemblies />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          minHeight: 352,
          minWidth: 250,
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 4,
          maxHeight: 405,
          overflowY: "auto",
          border: "1px solid #f1f1f1",
        }}
      >
        <Box sx={{ paddingTop: 2, pt: 3, pb:3 }}>
        <Grid container spacing={0} justifyContent="flex-end">
        <Grid item xs={12} sm={6} md={3}>
          <SearchLiveAssemblyGroups />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <ProductBrands dataSupplierFacets={dataSupplierFacets} />
        </Grid>
      </Grid>
        </Box>
        {vehicleProductGroups ? (
            <AssemblyTree group={vehicleProductGroups} />
         ) : (
          <div>No data available</div>
         )}
      </Box>
               </Grid>
            </Grid>
         </Box>

         <Footer/>
      </>
   )
}

export default AssemblyTreePage;