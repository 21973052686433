import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import theme from "../../theme";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ArticleCriteria } from "../../interfaces/ArticleCriteria";
import Skeleton from "@mui/material/Skeleton";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ProductCriteria = (props: any) => {
  const { groupedCriteria, onCriteriaChange } = props; // Destructure onCriteriaChange
  const [checkedItems, setCheckedItems] = React.useState<Record<string, boolean>>({});
  const [disabled, setDisabled] = React.useState(false);
  const [expandedAccordion, setExpandedAccordion] = React.useState<string | null>(null);

  React.useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const criteriaParam = urlParams.getAll("criteria");

    if (criteriaParam.length > 0) {
      const criteriaIds = criteriaParam.map((param) => {
        const [idStr, rawValueStr] = param.split(":");
        return {
          criteriaId: parseInt(idStr, 10),
          rawValue: decodeURIComponent(rawValueStr),
        };
      });

      const initialCheckedItems: Record<string, boolean> = {};
      criteriaIds.forEach(({ rawValue }) => {
        initialCheckedItems[rawValue] = true;
      });

      setCheckedItems(initialCheckedItems);
      setDisabled(true);
      // Fitting Position

      const firstCheckedAccordion = Object.keys(groupedCriteria).find((description) => {
        return groupedCriteria[description].some(
          (criteria: { rawValue: string | number }) => initialCheckedItems[criteria.rawValue]
        );
      });

      if (firstCheckedAccordion) {
        setExpandedAccordion(firstCheckedAccordion);
      }
    }
  }, [groupedCriteria]);

  const handleChange = (criteria: ArticleCriteria) => {
    const { criteriaId, rawValue } = criteria;
    const updatedCheckedItems = { ...checkedItems };
    const isChecked = !updatedCheckedItems[rawValue];

    updatedCheckedItems[rawValue] = isChecked;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("criteria");

    Object.entries(updatedCheckedItems).forEach(([rawValue, isChecked]) => {
      if (isChecked) {
        const criteriaParam = `${criteriaId}:${encodeURIComponent(rawValue)}`;
        urlParams.append("criteria", criteriaParam);
      }
    });

    const newUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
    setDisabled(true);
    window.history.pushState({}, '', newUrl);

    setCheckedItems(updatedCheckedItems);

    // Call onCriteriaChange (updateCriteriaFilters) when the checkbox is toggled
    if (onCriteriaChange) {
      onCriteriaChange(criteriaId, rawValue, isChecked);
    }
  };

  return (
    <>
      {groupedCriteria ? (
        <Box sx={{ maxHeight: '260px', overflowY: 'auto', width: "100%", border: "4px solid #f1f1f1", mt: 3 }}>
        <Grid container spacing={0}>
          {[
            // Add "Fitting Position" only if it exists in the object
            ...(groupedCriteria["Fitting Position"] ? ["Fitting Position"] : []),
            // Add the rest of the keys excluding "Fitting Position"
            ...Object.keys(groupedCriteria).filter(
              (criteriaDescription: string) => criteriaDescription !== "Fitting Position"
            ),
          ].map((criteriaDescription: string) => (
            <Accordion
              key={criteriaDescription}
              expanded={expandedAccordion === criteriaDescription}
              onChange={() =>
                setExpandedAccordion(
                  expandedAccordion === criteriaDescription
                    ? null
                    : criteriaDescription
                )
              }
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon sx={{ fontSize: "15px" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  bgcolor: "#f1f1f1",
                  minHeight: "30px",
                  "&.css-eqpfi5-MuiAccordionSummary-content": {
                    marginTop: 0,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    fontWeight: "normal",
                  }}
                >
                  {criteriaDescription}
                </Typography>
              </AccordionSummary>
              {groupedCriteria[criteriaDescription].map(
                (criteria: ArticleCriteria) => (
                  <AccordionDetails
                    key={`${criteria.criteriaId}-${criteria.rawValue}`}
                    sx={{
                      paddingTop: 0,
                      paddingLeft: 1,
                      paddingBottom: 0,
                    }}
                  >
                    <Item sx={{ display: "flex", flexWrap: "wrap", p: 0 }}>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="flex-start"
                        sx={{
                          overflowX: "hidden",
                          overflowY: "auto",
                          maxHeight: "300px",
                        }}
                      >
                        <Grid item xs={12}>
                          <FormControlLabel
                            label={
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  fontFamily: "Inter",
                                  fontWeight: "normal",
                                }}
                              >
                                {criteria.formattedValue}
                              </Typography>
                            }
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={checkedItems[criteria.rawValue] || false}
                                onChange={() => handleChange(criteria)}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  textTransform: "capitalize",
                                  color: theme.palette.secondary.main,
                                  "&.Mui-checked": {
                                    color: theme.palette.secondary.main,
                                  },
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              />
                            }
                            sx={{
                              color: theme.palette.text.primary,
                              width: "100%",
                              "&.MuiFormControlLabel-label": {
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: "normal",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Item>
                  </AccordionDetails>
                )
              )}
            </Accordion>
          ))}
        </Grid>
      </Box>
      ) : (
        <Box sx={{ width: "100%", border: "4px solid #f1f1f1", mt: 3 }}>
          <Grid container spacing={0}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Accordion
                key={index}
                expanded={false}
                sx={{ width: "100%" }}
              >
                <AccordionSummary
                  expandIcon={<Skeleton animation="wave" sx={{ width: "15px" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    bgcolor: "#f1f1f1",
                    minHeight: "30px",
                    "&.css-eqpfi5-MuiAccordionSummary-content": {
                      marginTop: 0,
                    },
                  }}
                >
                  <Skeleton animation="wave" sx={{ width: '80px' }} />
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0, paddingLeft: 1, paddingBottom: 0 }}>
                  <Item sx={{ display: "flex", flexWrap: "wrap", p: 0 }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label={<Skeleton animation="wave" sx={{ width: '80px' }} />}
                          control={<Skeleton animation="wave" sx={{ width: '20px', height: '15px', ml: 2, mr: 1 }} />}
                          sx={{
                            color: theme.palette.text.primary,
                            width: "100%",
                            "&.MuiFormControlLabel-label": {
                              fontSize: "13px",
                              fontFamily: "Inter",
                              fontWeight: "normal",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Item>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProductCriteria;
