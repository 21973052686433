import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../components/common/header/Header";
import ProductCategories from "../components/products/ProductCategories";
import { Article } from "../interfaces/Article";
import { GenericArticle } from "../interfaces/GenericArticle";
import { DataSupplierFacet } from "../interfaces/DataSupplierFacet";
import ProductBrands from "../components/products/ProductBrands";
import ProductItem from "../components/products/ProductItem";
import ProductCriteria from "../components/products/ProductCriteria";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Footer from "../components/common/Footer/Footer";
import { GroupedCriteria } from "../interfaces/GroupedCriteria";
import { CriteriaFilters } from "../interfaces/CriteriaFilters";
import CustomBreadcrumbs from "../components/common/Breadcrumbs/Breadcrumbs";
import theme from "../theme";
import { Typography } from "@mui/material";
import CompareProducts from "../components/products/CompareProducts";
import { VehicleProductGroup } from "../interfaces/VehicleProductGroup";
import LocalProductItem from "../components/products/LocalProductItem";
import { getOilData } from "../services/core-api";

interface Film {
   title: string;
   year: number;
 }

const OilSearchPage = () => {
  const [genericArticles, setGenericArticles] = React.useState<GenericArticle[]>([]);
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(null);
  const [linkageTargetType, setLinkageTargetType] = React.useState<string | null>(null);
  const [dataSupplierFacets, setDataSupplierFacets] = React.useState<DataSupplierFacet | null>(null);
  const [assemblyGroupFacets, setAssemblyGroupFacets] = React.useState<VehicleProductGroup | null>(null);
  const [articles, setArticles] = React.useState<Article[] | null>(null);
  const [articleNumber, setArticleNumber] = React.useState('');
  const [categoryId, setCategoryId] = React.useState<number | null>(null);
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [assemblyGroupNodeIds, setAssemblyGroupNodeIds] = React.useState<number | null>(null);
  const [groupedCritera, setGroupedCriteria] = React.useState<GroupedCriteria | null>(null);
  const [criteriaFilters, setCriteriaFilters] = React.useState<CriteriaFilters[] | null>(null);
  const [isWrongPath, setIsWrongPath] = React.useState(false);

  // Pagination
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Film[]>([]);

  const [comparisonArticles, setComparisonArticles] = React.useState<Article[]>([]);

  const updateComparisonArticles = (article: Article, isChecked: boolean) => {
   if (isChecked) {
     // Add article to comparison array
     setComparisonArticles(prevArticles => [...prevArticles, article]);
   } else {
     // Remove article from comparison array
     setComparisonArticles(prevArticles =>
       prevArticles.filter(a => a !== article)
     );
   }
 };

 const isFirstRun = React.useRef(true);

 React.useEffect(() => {
   if (isFirstRun.current) {
     isFirstRun.current = false;
     
     const urlParams = new URLSearchParams(window.location.search);
     const linkageTargetType = urlParams.get('linkageTargetType');
     const linkageTargetId = urlParams.get('linkageTargetId');
     const categoryIdParam = urlParams.get("category");
     const articleNumber = urlParams.get("viscosity");
     const brandIdParam = urlParams.get("brand");
     const assemblyGroupNodeIdsParam = urlParams.get("assemblyGroupNodeIds");
 
     if (linkageTargetId) {
       setLinkageTargetId(parseInt(linkageTargetId, 10));
     }
     
     if (linkageTargetType) {
       setLinkageTargetType(linkageTargetType);
     }
 
     if (articleNumber) {
       setArticleNumber(articleNumber);
     }
 
     if (brandIdParam) {
       setBrandId(parseInt(brandIdParam, 10));
     }
 
     if (categoryIdParam) {
       setCategoryId(parseInt(categoryIdParam, 10));
     }
 
     if (assemblyGroupNodeIdsParam) {
       setAssemblyGroupNodeIds(parseInt(assemblyGroupNodeIdsParam, 10));
     }
 
     // Extract criteria parameters
     const criteriaParams: CriteriaFilters[] = [];
     urlParams.forEach((value, key) => {
       if (key === "criteria") {
         const [criteriaId, rawValue] = value.split(":");
         criteriaParams.push({
           criteriaId: parseInt(criteriaId, 10),
           rawValue,
         });
       }
     });
 
     setCriteriaFilters(criteriaParams);
   }
 }, []);

  React.useEffect(() => {
   if (isFirstRun.current) return;
   
   let isMounted = true;
   
   (async () => {
     try {
       const options = {
         viscosity: articleNumber,
         page: page,
         manufacturer: brandId,
         category: categoryId,
         linkageTargetType: linkageTargetType,
         linkageTargetId: linkageTargetId,
         assemblyGroupNodeIds: assemblyGroupNodeIds,
         criteria: criteriaFilters,
       };
       
       const articlesResponse = await getOilData(options);
       if (!articlesResponse || !isMounted) return;
       
       const articles = articlesResponse.articles;
       const extractedGenericArticles = articles
         .map(article => article.tec_doc_article ? article.genericArticles?.[0] : null)
         .filter((article): article is GenericArticle => article !== null);
 
       setCount(articlesResponse.maxAllowedPage);
       setArticles(articles);
 
       // Use Set to store unique genericArticleIds
       const uniqueIds = new Set();
       const filteredGenericArticles = extractedGenericArticles.filter(article => {
         if (uniqueIds.has(article.genericArticleId)) {
           return false;
         } else {
           uniqueIds.add(article.genericArticleId);
           return true;
         }
       });
 
       setGenericArticles(filteredGenericArticles);
 
       if (articlesResponse.assemblyGroupFacets.counts) {
         setAssemblyGroupFacets(articlesResponse.assemblyGroupFacets.counts);
       }
 
       if (articlesResponse.dataSupplierFacets) {
         setDataSupplierFacets(articlesResponse.dataSupplierFacets);
       }
 
       if (categoryId && articlesResponse.groupedCriteria) {
         setGroupedCriteria(articlesResponse.groupedCriteria);
       }
       
     } catch (error) {
     }
   })();
 
   return () => {
     isMounted = false;
   };
 }, [articleNumber, brandId, criteriaFilters, page, assemblyGroupNodeIds]);

  // Use another useEffect to log the state whenever it changes
React.useEffect(() => {
}, [dataSupplierFacets]);

 React.useEffect(() => {
   if (!open) {
     setOptions([]);
   }
 }, [open]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      {articles ? (
        <Box
          sx={{
            flexGrow: 1,
            m: 2,
            minHeight: '71vh',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ProductCategories genericArticles={genericArticles} />
              {categoryId !== null && (
                <ProductCriteria groupedCriteria={groupedCritera} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
              <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={5} sx={{paddingLeft: '0px !important', }}>
                    <CustomBreadcrumbs assemblyGroupFacets={assemblyGroupFacets} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <Box sx={{ display: 'flex', justifyContent:"flex-end", pr: 1 }}>
                      <Box sx={{ mr:1 }}>
                        <CompareProducts compareArticles={comparisonArticles}/>
                      </Box>
                      <Box sx={{ mr:1 }}>
                        <ProductBrands dataSupplierFacets={dataSupplierFacets} />
                      </Box>
                     
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 4 }}>
                {articles.map((article, index) => (
                  article.tec_doc_article ? (
                    <ProductItem key={index} article={article} onToggle={updateComparisonArticles} />
                  ) : (
                    <LocalProductItem key={index} article={article} />
                  )
                ))}
              </Box>
            </Grid>
          </Grid>
          <Stack spacing={2} sx={{ pt: 2 }}>
            <Pagination
              count={count}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </Stack>
        </Box>
      ) : isWrongPath ? (
        <Box
             sx={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
               height: "100vh",
             }}
           >
             <div>
               <img
                 style={{ width: "100px", height: "auto" }}
                 src="./no_data.png"
                 alt="No data"
               />
             </div>
             <div>
               <Typography
                 variant="h6"
                 sx={{
                   color: theme.palette.secondary.dark,
                   fontFamily: "Inter",
                   fontWeight: "normal",
                 }}
               >
                 No Data!
               </Typography>
             </div>
           </Box>
      ) : (
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Box
            sx={{
              flexGrow: 1,
              m: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2.5}>
                <ProductCategories />
                <ProductCategories />
              </Grid>
              <Grid item xs={12} sm={12} md={9.5}>
                <Box
                  sx={{
                    bgcolor: "#f1f1f1",
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: 1,
                    borderTop: `2px solid ${theme.palette.primary.dark}`,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={7} sx={{paddingLeft: '0px !important', paddingTop: '0px !important'}}>
                      <CustomBreadcrumbs />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Box sx={{ display: 'flex', alignItems: 'center', pr: 1 }}>
                        <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                        <Skeleton animation="wave" sx={{ width: '200px', height: '30px' }} />
                        </Box>
                        <Box sx={{ flexGrow: 3, marginRight: 2 }}>
                        <Skeleton animation="wave" sx={{ width: '200px', height: '30px' }} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <Skeleton animation="wave" sx={{ width: '200px', height: '30px' }} />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <ProductItem />
                </Box>
              </Grid>
            </Grid>
            <Stack spacing={2} sx={{ pt: 2 }}>
              <Skeleton animation="wave" sx={{ width: '100px' }} />
            </Stack>
          </Box>
        </Stack>
      )}
      <Footer />
    </>
  );
}

export default OilSearchPage;
