import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RecentVehicleSearch } from "../../../interfaces/core/RecentVehicleSearch";
import { getRecentVehiclesSearch } from "../../../services/core-api";

const RecentSearch = () => {
  const [recentVehicles, setRecentVehicles] = React.useState<
    RecentVehicleSearch[]
  >([]);

  React.useEffect(() => {
    let activeRecentSearch = true;

    if (activeRecentSearch){
      (async () => {
        try {
          const result = await getRecentVehiclesSearch();
          if (result) {
            setRecentVehicles(result);
          }
        } catch (error) {
        }
      })();
    }

    return () => {
      activeRecentSearch = false;
    }
  }, []);

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "none",
        borderRadius: 0,
      }}
    >
      <TableContainer sx={{ maxHeight: 275, overflowX: 'auto', overflowY: 'auto' }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>

                <TableCell
                  align="left"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Vehicle Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Model Year
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Performance
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Engine code
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentVehicles
              .map((vehicle) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={vehicle.id}
                    onClick={() => {
                      // Handle click event
                      const url = `/assemblies?linkageTargetId=${vehicle.tec_doc_type_no}&linkageTargetType=${vehicle.linkage_target_type}`;
                      window.location.href = url;
                    }}
                    style={{ cursor: "pointer" }} // Change cursor to pointer on hover
                  >
                        <TableCell
                          align="left"
                          style={{ fontSize: "12px", padding: "5px" }}
                        >
                          {vehicle.vehicle_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: "12px", padding: "5px" }}
                        >
                          {vehicle.vehicle_model_year}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: "12px", padding: "5px" }}
                        >
                          {vehicle.performance}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: "12px", padding: "5px" }}
                        >
                          {vehicle.engine_code}
                        </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RecentSearch;
