import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../components/common/header/Header";
import { Article } from "../interfaces/Article";
import Stack from "@mui/material/Stack";
import Footer from "../components/common/Footer/Footer";

import theme from "../theme";
import { Typography, Breadcrumbs, Container } from "@mui/material";
import { getTyres } from "../services/core-api";
import LocalProductItem from "../components/products/LocalProductItem";

const TireProductSearchPage = () => {
   const [articles, setArticles] = React.useState<Article[]>([]);
   const [page, setPage] = React.useState(1);
  const loading = articles.length === 0;
  const [isWrongPath, setIsWrongPath] = React.useState(false);

 const isFirstRun = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const urlParams = new URLSearchParams(window.location.search);
      const season = urlParams.get("season");
      const width = urlParams.get("width");
      const height = urlParams.get("height");
      const dimension = urlParams.get("dimension");
      const manufacturer = urlParams.get("manufacturer");
      

      if (width == null && height == null && dimension == null && manufacturer == null) {
         setTimeout(() => {
            setIsWrongPath(true)
         }, 1000);
      }

      (async () => {
         try {
            const options = {
               season: season,
               width: width,
               height: height,
               dimension: dimension,
               manufacturer: manufacturer,
               page: page
            }
            const response = await getTyres(options);
            if (response) {
               setArticles(response.tyres);
            }
         } catch (error) {
         }
      })()
      
    }
    }, []);

return (
   <>
      <Header/>
         <Box
          sx={{
            flexGrow: 1,
            m: 2,
            minHeight: '71vh',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
         <Container maxWidth="lg">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
               <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
               >
                  <Grid container spacing={1}>
                     <Grid item xs={12} sm={12} md={5} sx={{paddingLeft: '0px !important', }}>
                        <Stack spacing={2} sx={{paddingTop: '3px', paddingLeft: '5px'}}>
                           <Breadcrumbs aria-label="breadcrumb">
                              <Typography key="3"
                                 sx={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    fontFamily: "Inter",
                                 }}
                              >
                                 Goma
                              </Typography>
                           </Breadcrumbs>
                        </Stack>
                     </Grid>
                  </Grid>
               </Box>
               <Box sx={{ mt: 4 }}>
               {articles.map((article, index) => (
                    <LocalProductItem key={index} article={article} />
                ))}
               </Box>
            </Grid>
            </Grid>
         </Container>
        </Box>
      <Footer/>
   </>
)
}
export default TireProductSearchPage;
